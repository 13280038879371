
.reset-heading{

    display: block;
    width: 50%;
    margin: auto;
}

.reset-password-main-div{

    display: block;
    width: 75%;
    margin: 5% auto;
}

.reset-password-form{

    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    width: 50%;
    margin: auto;
}

.reset-password-form #checkbox{

    width: 18px;
    height: 18px;
}   

.reset-inputs{

    margin: 6% 1% 0% 1%!important;
}

.reset-inputs label{
    background-color: white !important;
    padding: 0% 1%;
}

.error-msg{

    color: red;
    font-size: 14px;
    margin: 1% 1%;
    text-align: left;
}

.show-password-div{

    text-align: left;
    margin: 2% 1%;
}

.show-password-div label{
    margin: 0% 1%;
}

.reset-button{

    display: block;
    width: 25%;
    margin: 2% auto;
    padding: 1.5% 1%;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    font-size: 18px;
    color:aliceblue;
    background-color: #3498DB;
    cursor: pointer;
}
.reset-button:hover{
    
    background-image: linear-gradient( #3491cf,#3297dba8);
}


@media(max-width:768px){

    .reset-password-form{

        width: 85%;
    }
}

@media(max-width:500px){

    .reset-password-form{

        width: 100%;
    }

    .reset-button{

        width: 40%;
        padding: 2.5% 1%;
        font-size: 17px;
    }
}