* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.check-valid-text {
  margin: 1% 0%;
  color: red;
}

.Error_messages{
  color: red;
  margin-bottom: 0%;
  font-size: 95%;
  margin-left: 1%;
}

.signupform form label {
  padding: 0% 1% !important;
  background-color: white !important;
  z-index: 1 !important;
}

.signup-dialog form label {
  padding: 0% 1% !important;
  background-color: white !important;
  z-index: 1 !important;
}

.signupform form fieldset {
  top: 0px !important;
}

.PrivateNotchedOutline-root-1 {
  top: 0px !important;
}

.signupform {
  margin: auto;
  margin-top: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 60%;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 2%;
}

.signup-profile-save-btn {
  background-color: #01aced !important;
  margin-left: 1% !important;
  border-radius: 15px !important;
  width: 20%;
  color: white !important;
}

.signup-dialog MuiDialogActions-root {
  justify-content: center;
}
.signupform h2 {
  font-size: 20px;
}

.signupform button {
  background: transparent;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.signupform .b1 {
  color: aliceblue;
  background: #03a9fa;
}

.signupform .b1:hover {
  background-color: rgba(85, 138, 224);
  color: aliceblue;
}

.signup-next-btn {
  background-color: #01aced !important;
  color: white !important;
  width: 20%;
  font-size: large;
}
.google-btn {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 40%;
  padding: 1%;
  margin: auto;

  display: flex;
  margin-top: -2%;
  margin-bottom: 2%;
  justify-content: space-evenly;
  cursor: pointer;
}
.google-btn-card {
  margin-left: 30%;
}

.alternate-emails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag-item {
  padding: 0.25% 0.5%;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  margin: 0.5% 0.4%;
  display: inline-block;
}

.email-cancel-button {
  border: none;
  background-color: hsl(0, 0%, 90%);
  border-radius: 10px;
  line-height: 0.8;
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
}

.red-border-select .select__control {
  border: 2px solid crimson !important;
}

.error-msg {
  color: red;
  margin: 1% 0.5%;
}

.select__menu {
  position: relative !important;
}

.plus-icon {
  cursor: pointer;
  color: darkgrey;
}

.signup-dialog .select__menu {
  position: absolute !important;
  z-index: 4 !important;
}

.dialog-action-buttons {
  justify-content: space-between !important;
}

.dialog-back-button {
  margin-right: 1% !important;
  border-radius: 15px !important;
  width: 10%;
  color: #01aced !important;
  text-align: left;
  font-weight: 600;
  border: 1px solid #01aced !important;
}

.dialog-back-button i {
  margin-right: 10%;
}


@media only screen and (max-width: 700px) {
  .signupform {
    margin: auto;
    margin-top: 5%;
    width: 90%;
  }
}

@media (max-width: 768px) {
  .google-btn {
    width: 80%;
    margin: auto;
  }
}
