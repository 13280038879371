/* ********************************Current Brands CSS********************************* */

.displayed-brand {
  display: inline-block;
  padding: 0.25% 0.5%;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  margin: 2% 1%;
  margin-bottom: 2%;
}

.brand-cancel-button {
  border: none;
  background-color: hsl(0, 0%, 90%);
  border-radius: 10px;
  line-height: 0.8;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 500;
}

/* *********************************************************************************** */
/* *********************************************Actual Brands Display***************************************** */

.saved-brands {
  display: flex;
  flex-wrap: wrap;
}

/* ************************************************************************************** */

.light-heading-h4 {
  color: rgba(32, 32, 32, 0.753);
  font-size: larger;
}
.profile-edit-icon {
  margin: 2% 0%;
  margin-left: 90%;
}
.profile-edit-icon:hover {
  cursor: pointer;
}
.profile-card {
  margin: 2% auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 80%;
  padding: 3%;
}

.profile-textfeild {
  width: 90%;
  position: relative;
}
.profile-section-img {
  width: 100%;
  /* border-radius: 50%; */
}

@media only screen and (max-width: 700px) {
  .profile-card {
    margin-left: 5%;
    margin-top: 5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 90%;
    padding: 3%;
  }
  .profile-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
  }
}

.profile-page-container {
  background-color: rgb(241, 241, 241);
  display: grid;
  grid-template-columns: 50% 50%;
  padding-bottom: 5%;
}

.profile-page-section1 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 5%;
  background-color: #ffff;
}

.Top-heading-and-para {
  margin-top: 2%;
  padding-left: 2.5%;
}

.horizontal-line {
  width: 95%;
  margin-left: 2.5%;
  border: 1px solid grey;
}

.Profile-picture-container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #ffff;
  width: 50%;
  padding: 3%;
  margin: 5% auto;
}

.Profile-img-container {
  display: flex;
  justify-content: center;
}

#input {
  width: 100%;
  margin-top: 5%;
  padding-left: 18%;
}

.profile-Picture-heading {
  font-size: 145%;
  margin-bottom: 0%;
}

.profile-Picture-para {
  text-align: left;
  margin-bottom: 5%;
}

.profile-Picture-btn {
  border: none;
  width: 100%;
  padding: 2%;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: white;
}

@media only screen and (max-width: 812px) {
  .profile-page-container {
    background-color: rgb(241, 241, 241);
    display: grid;
    grid-template-columns: 1fr;
  }

  .profile-page-section1 {
    order: 2;
  }
  /* section second */
  .Profile-picture-container {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffff;
    width: 90%;
    margin-left: 5%;
    padding: 3%;
    margin-top: 5%;
    order: 1;
  }

}

@media only screen and (width: 1024px) {
  .profile-page-container {
    background-color: rgb(241, 241, 241);
    display: grid;
    grid-template-columns: 1fr;
  }

  .profile-page-section1 {
    order: 2;
  }

  /* section second */
  .Profile-picture-container {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffff;
    width: 90%;
    margin-left: 5%;
    padding: 3%;
    margin-top: 5%;
    order: 1;
  }

  .profile-Picture-para {
    text-align: left;
    margin-bottom: 5%;
    padding-left: 4%;
  }

}
