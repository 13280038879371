.bids-header {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items:flex-end;
}
.bids-header-title{
  margin-bottom: 0%;
  line-height: 1;
}
.bids-header-dropdown{
  margin: 0% 1%;
}
.bid-list-card {
  margin-left: 10%;
  width: 80%;
  padding: 2%;
}

/* ********************************************New Bids card Css************************************************** */

.bid-cards-wrapper {
  display: grid;
  grid-template-columns: 1fr ;
  margin: 2% auto;
  width: 75%;
}

.bid-cards {
  margin: 4% 4%;
  box-shadow: 0 5px 12px rgb(0 0 0 / 0.2);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: whitesmoke;
  /* animation: fadeInLeft 1s ease-in-out; */
}

.bid-cards-top {
  padding: 3% 5%;
  padding-bottom: 10%;
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: aliceblue;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.bid-cards-bottom {
  padding: 2% 5%;
  padding-top: 8%;
  margin-top: -6%;
  background-color: whitesmoke;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 450px;
  overflow-y: auto;
}

.bid-cards-bottom p {
  margin: 4% 0%;
}

.cards-bottom-span1 {
  font-weight: bold;
}

/* *************************************************************************************************************** */

/* ********************************bid list diaplay css*************************************** */
.typography-title-div {
  display: grid;
  margin: 1% 0%;
}
.typography-title-div p span {
  font-weight: 600;
}
/* ******************************************************************************************** */
.no-bids-yet-wrapper {
  text-align: center;
  align-items: center;
  /* margin-top: 2%; */
}
.no-bid-inner-div {
  width: 96%;
  margin-left: 2%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.no-bid-text-area {
  text-align: center ;
  width: 100%;
}
.no-bid-text-area h3{
  text-align: left;
  margin-left: 20%;
  margin-top: 20%;
}
.no-bid-img-area {
  width: 100%;
}
.no-bid-view-quotes {
  background: rgb(1, 68, 138) !important;
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  ) !important;
  color: white !important;
}
.no-bids-yet-inner-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 1% 3%;
}
.no-bids-yet-inner-div img {
  width: 65%;
  display: block;
  margin: auto;
}

.no-bids-yet-inner-div h3 {
  text-align: left;
  margin: auto 2%;
}

.bids-donate-btn {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.email-btn {
  background-color: #01aced !important;
  margin-right: 1% !important;
  border-radius: 15px !important;
  width: 20%;
  color: white !important;
}
.donate-btn {
  background-color: #eef139 !important;
  margin-left: 1% !important;
  border-radius: 15px !important;
  width: 20%;
  color: rgb(0, 0, 0) !important;
}

.no-bid-img {
  width: 80%;
  display: block;
  margin: auto;
}
.contact-details{
  color:#0d6efd;
}
@media only screen and (max-width: 700px) {
  .bid-list-card {
    margin-left: 5%;
    width: 90%;
    padding: 2%;
  }
  .donate-btn,
  .email-btn {
    width: 50%;
  }
  .no-bid-inner-div {
    flex-direction: column;
  }
  .no-bid-text-area h3,
  h2 {
    margin-left: 5%;
  }
  .no-bid-text-area h6 {
    margin-left: 5%;
  }
  .no-bid-img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .bid-cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 450px) {
  .no-bids-yet-inner-div {
    grid-template-columns: 1fr;
  }
  .no-bid-inner-div {
    flex-direction: column;
  }
  .no-bids-yet-inner-div h3 {
    text-align: center;
  }
  .no-bid-text-area h3,
  h6,
  h2 {
    margin-left: 5%;
  }
  .bid-cards-wrapper {
    grid-template-columns: 1fr;
  }
}
