.profile-card {
  margin: 5% auto;
  margin-bottom: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 90%;
  padding: 3%;
  background-color: #ffff;
}

.customer-profile-page-container {
  background-color: rgb(241, 241, 241);
  display: grid;
  grid-template-columns: 50% 50%;
  padding-bottom: 5%;
}

.light-heading-h4 {
  color: rgba(32, 32, 32, 0.753);
  font-size: larger;
  margin-left: 3%;
}
.light-heading-h3 {
  /* border: 1px solid green; */
  color: rgba(32, 32, 32, 0.753);
  font-size: xx-large;
  text-align: center;
  margin: 1% 0%;
}
.profile-edit-icon {
  margin-left: 85%;
}
.profile-edit-icon:hover {
  cursor: pointer;
}

.customer-profile-Picture-heading {
  margin-bottom: 5%;
}

.profile-card {
  margin-left: 10%;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 80%;
  padding: 3%;
}

.customer-Profile-picture-container {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #ffff;
  width: 50%;
  padding: 3%;
  margin: 5% auto;
}

.customer-Profile-img-container {
  align-items: center;
  text-align: center;
}

.customer-profile-Picture-btn {
  border: none;
  width: 100%;
  padding: 2%;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: white;
}

.profile-pic {
  width: 100%;
  margin-left: 0%;
  display: block;
  margin: 1% auto;
}

.profile-pic-default {
  width: 70%;
  display: block;
  margin: auto;
}

.choose-img {
  margin: 1% 0% 5% 0%;
}

.profile-textfeild {
  width: 90%;
  position: relative;
}
@media(max-width: 550px) {
  .profile-card {
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    width: 90%;
    padding: 3%;
    order: 2;
    display: block;
    margin:5% auto;
  }
  .profile-pic {
    width: 100%;
  }
  .profile-row {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    padding: 5%;
  }
  .customer-Profile-picture-container {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
  }
  .customer-Profile-img-container {
    align-items: center;
    text-align: left;
  }
.customer-profile-page-container {

  grid-template-columns: 1fr;
}
}
