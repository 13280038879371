.paypal-invoice-wrap {
  width: 70%;
  margin: 5% auto;
}

.transaction-status1 {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: rgb(13, 110, 13);
  text-shadow: 1px 2px 2px rgb(160, 160, 160);
}

.transaction-status2 {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: rgb(167, 26, 26);
  text-shadow: 1px 2px 2px rgb(160, 160, 160);
}
.invoice-download-btn {
  background: #01aced !important;
  color: white !important;
  padding: 1% !important;
  width: 50% !important;
  font-variant: small-caps !important;
}
.invoice-download-btn-btn {
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: white !important;
  font-variant: small-caps !important;
}
.invoice-table-card {
  margin-left: 10%;
  width: 80% !important;
}
.paynow-btn {
  background: rgba(28, 161, 28, 0.747) !important;
  width: 40%;
  color: white !important;
}

.paynow-btn-disabled{
  background: darkgray !important;
  width: 40%;
  color: white !important;
}

.paynow-btn:hover,
.invoice-download-btn-btn:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.payed-btn {
  background: rgba(197, 192, 186, 0.747) !important;
  width: 40%;
  color: white !important;
}
@media (max-width: 768px) {
  
  .invoice-table-card {
    margin-left: 2%;
    width: 96% !important;
  }
  .paynow-btn {
    width: 100% !important;
    font-size: 0.3cm !important;
    margin-left: -10% !important;
  }
}
