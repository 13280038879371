.add-vehicle-form label{

  padding: 0% 1% !important;
  background-color: white !important;
}

.add-vehicle-form fieldset{

  top: 0px !important;
}

.add-vehicle-form {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 2%;
  width: 60%;
  display: block;
  margin: auto;
  margin-top: 5%;
}

.add-vehicle-form .select__menu{

  position:absolute !important;
  z-index: 100;
}

.zipCode_error_check{
  font-size: 90%;
  margin-bottom: 0%;
  margin-top: 0.5%;
  color: red;
  font-weight: 600;
}

Grid TextField input{

  padding: 2% 2%;
}

@media only screen and (max-width: 700px) {
  .add-vehicle-form {
    margin-left: 5%;
    width: 90%;
    padding: 5%;
  }
}
