.waiting-card-list{
    display: flex;
    justify-content: center;
    margin-top: 2%;
}
.waiting-card-item{
    width: 25%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 2%;
    margin-right: 5%;
    margin-top: 5%;
}

#waiting-card-item-1{
    box-shadow: 0 2px 8px 4px rgba(52, 152, 219, 0.5) !important;
    transform: scale3d(1.1,1.1,1.1);
}


.waiting-heading{
    
    color: rgba(0, 0, 0, 0.692);
    text-align: center;
    margin-top: 5%;
    letter-spacing: 0.2px;
}
.waiting-card-title{
    color: rgba(0, 0, 0, 0.774);
    font-size: large;
    margin-top: 5%;
    text-align: center;
}

@media only screen and (max-width: 700px) {
    .waiting-card-list{
        display: flex;
        justify-content: center;
        margin-top: 2%;
        flex-direction: column;
    }
    .waiting-card-item{
        width: 90%;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        padding: 2%;
        margin-left: 5%;
        
    }
  
}