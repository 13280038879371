.customer-login-box{
    width: 60%;
    margin-left: 20%;
    margin-top: 5%;
    box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),-8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 10px; 
    padding: 2%;
  border: 1px solid grey;
}

.customer-login-box h2{

  margin: 5% auto;

}

.customer-login-box img{

    display: block;
    margin:1% auto;
}

.customer-login-box a{

    display: block;
    text-align: center;
    margin:1% auto;
}

.customer-login-hero-section{
    display: grid;
  grid-template-columns: 0.8fr 1fr;
  
}
@media only screen and (max-width: 700px) {
    .customer-login-hero-section {
        grid-template-columns: 1fr;
      }
}