* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.when-no-data{

  text-align: center;
  margin: 10% 0%;
}

.invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1%;
}

.invoice h1 {
  margin: 1%;
}

.invoice select {
  margin: 0.5%;
}

.search-box-2 {
  display: grid;
  margin: auto;
  margin-top: 2%;
  width: 70%;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
}
.search-box-2 input {
  width: 70%;
}

/* *************************************************************************************************** */
.submitted-quotes-card-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  margin: auto;
  margin-top: 4%;
  padding: 2%;
}
.icon-detail {
  width: 50%;
}

.submitted-quotes-card-main-container a{
  text-decoration: none;
  color: inherit;
}
.submitted-quotes-card-container {
  border-radius: 20px 20px 0px 0px;
}

.submitted-quote-card-stack-text {
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: aliceblue;
  padding: 2%;
  padding-bottom: 5%;
  border-radius: 20px 20px 0px 0px;
}

.submitted-quote-card-stack-text h2{

  text-align: center;
  cursor: pointer;
  display: block;
  margin: 2%;
}

.submitted-quote-card-stack-text h5 {
  margin-left: 5%;
}
.submitted-quote-card-stack-text small {
  margin-left: 5%;
}

/* .submitted-quote-card-stack {
  animation: fadeInLeft 1s;
} */

.submitted-quote-card-stack-down {
  border-radius: 20px 20px 0px 0px;
  margin-top: -5%;
  background-color: whitesmoke;
  padding: 5%;
  /* animation: fadeInLeft 1s; */
}
.submitted-quotes-card-container {
  margin: 5%;
  background-color: whitesmoke;
  box-shadow: 0 5px 12px rgb(0 0 0 / 0.2);
}

.submitted-quotes-card-container:hover {
  transition: all 0.5s;
  transform: translateY(-10px);
  background-image: whitesmoke;
}

.buttons-div {
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;
}

.buttons-div button {
  /* margin: 2% auto; */
  padding: 3% 8%;
  width: 90%;
  margin-bottom: 5%;
  border: none;
  font-weight: 500;
  border-radius: 5px;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );

  color: white;
}

.buttons-div button a {
  text-decoration: none;
  color: inherit;
}

.buttons-div button:hover {
  background-color: #038cc2;
}

.icon-detail {
  width: 50%;
}

.submitted-quote-card-stack-text {
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: aliceblue;
  padding: 2%;
  padding-bottom: 5%;
  border-radius: 20px 20px 0px 0px;
}
.submitted-quote-card-stack-text h5 {
  margin-left: 5%;
}
.submitted-quote-card-stack-text small {
  margin-left: 5%;
}
.submitted-quote-card-stack-down {
  border-radius: 20px 20px 0px 0px;
  margin-top: -5%;
  background-color: whitesmoke;
  padding: 5%;
}
.submitted-quotes-card-container {
  margin: 5%;
  background-color: whitesmoke;
  box-shadow: 0 5px 12px rgb(0 0 0 / 0.2);
}

.submitted-quotes-card-container:hover {
  transition: all 0.5s;
  transform: translateY(-10px);
  background-image: whitesmoke;
}
.buttons-div {
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;
}

.buttons-div button {
  /* margin: 2% auto; */
  padding: 3% 8%;
  width: 90%;
  margin-bottom: 5%;
  border: none;
  font-weight: 500;
  border-radius: 5px;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );

  color: white;
}

.buttons-div button a {
  text-decoration: none;
  color: inherit;
}

.buttons-div button:hover {
  background-color: #038cc2;
}

@media (min-width: 425px) and (max-width: 768px) {
 
  .submitted-quotes-card-main-container {
    grid-template-columns: 1fr 1fr;
  }
}