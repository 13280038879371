.dealer-forgot-password button {
  margin-top: 5%;
}

.dealer-login-hero-section {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
}
.login-hero-text {
  margin-top: 15%;
  margin-left: 15%;
  width: 100%;
}
.login-hero-text h1 {
  font-size: 50px;
  /* color: #3498db; */
  margin-left: 10%;
  margin-top: 1%;
  color: aliceblue;
}
.login-hero-text h5 {
  /* font-size: 40px; */
  margin-left: 11%;
  color: aliceblue;
  /* color: #0067ac; */
}
.login-hero-img-section {
  margin-left: 20%;
}
.login-hero-img {
  display: block;
  margin: auto;
  width: 70%;
}
.container-login {
  /* Background color */
  /* background-color: rgba(0, 0, 0, 0.3); */

  /* You can use gradient background color such as
    background: linear-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.1) 100%); */
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  /* Curved corners */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  border-bottom-left-radius: 20% 10%;
  border-bottom-right-radius: 20% 10%;
}
.dealer-login-card {
  width: 60%;
  margin-left: 20%;
  margin-top: -5%;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 1%;
  /* z-index: -1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
   */
  /* border: 1px solid grey; */
}

.dealer-login-card label {
  padding: 0% 1% !important;
  background-color: white !important;
}

.dealer-login-card fieldset {
  top: 0px !important;
}

.checkbox-div {
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-div label {
  cursor: pointer;
}

.checkbox-div input {
  margin-right: 1%;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.google-card {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border-radius: 5px;
  border-style: hidden;
  background: white;
  margin-bottom: 3%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1%;
  width: 40%;
  margin-left: 30%;
  padding-right: 5%;
}
.google-card img {
  width: 30px;
}
.dealer-login-card h2 {
  margin-top: 2%;
  margin-bottom: 5%;
  text-align: center;
  color: #003f7e;
}

.dealer-login-card img {
  display: block;
  margin: 1% auto;
}

.dealer-login-card a {
  display: block;
  text-align: center;
  margin: 1% auto;
}

.forgot-password {
  color: blue;
  text-align: center;
  cursor: pointer;
  margin: 2% auto;
}

.new-signup {
  color: blue;
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
}

@media only screen and (max-width: 700px) {
  .dealer-login-hero-section {
    grid-template-columns: 1fr;
  }
  .login-hero-section {
    grid-template-columns: 1fr;
  }
  .login-hero-img-section {
    margin: auto;
  }
  .dealer-login-card {
    width: 90%;
    margin: 1% auto;
  }
  .login-hero-text {
    margin-top: 15%;
    margin-left: 0%;
    width: 100%;
  }
  .login-hero-text h1 {
    font-size: 30px;
  }
  .google-card {
    width: 70%;
    margin-left: 15%;
  }
}

.btn-div{
  margin: auto !important;
}

@media (max-width: 768px) {
  .login-hero-text h1 {
    font-size: 35px;
  }
}
