.reg-form-card {
  margin: 8%;
  padding: 2%;
  /* border-style: solid;
  border-width: 0.02cm; */
  /* border-color: rgb(39, 166, 240); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.validation-error {

  color: red ;
  margin: 0.2% auto;
  font-size: 13px;
  font-weight: bold;
}

.signup-btn {
  background-color: rgb(39, 166, 240) !important;
  width: 100%;
  border-radius: 25px !important;
  /* border-color: rgb(39, 166, 240) !important; */
  margin-top: 2% !important;
  color: white !important;
}

.signup-btn:disabled{
  cursor: not-allowed;
}

.reg-form-card form label{

  padding: 0% 1% !important;
  background-color: white !important;
}

.reg-form-card form fieldset{

  top: 0px !important;
}

.captcha-wrapper{

  margin: 2% 0%;
  width: 40% !important;
}

.red-border-select .select__control{

  border: 2px solid crimson !important;
}

.reg-form-card .select__menu{

  position:absolute !important;
  z-index: 100;
}
