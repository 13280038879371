.confirm-box{

    margin: 2%;
    padding: 2%;
}

.buttons-holder{

    width: 50%;
    text-align: center;
    margin: auto;
}

.popup-input{

    display: block;
    padding: 1% 2%;
    margin: 1% auto;
    width: 50%;
    outline: none;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
}

.pay-with-text{

    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.close-button{

    padding: 1%;
    margin: 1%;
    border-radius: 20px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: rgb(70, 70, 70);
}

.close-button:hover{

    color: rgb(184, 51, 51);
    background-color: rgb(202, 202, 202);
}

.paypal-wrap{
    
    width: 40%;
    margin: 2% auto;
}

.transaction-status1{

    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: rgb(13, 110, 13);
    text-shadow: 1px 2px 2px rgb(160, 160, 160);
}

.transaction-status2{
    
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: rgb(167, 26, 26);
    text-shadow: 1px 2px 2px rgb(160, 160, 160);
}

@media(max-width:768px){
    
    .paypal-wrap{
        
        width: auto;
    }
}
@media(max-width:768px){

    .buttons-holder{

        width: auto;
    }
}