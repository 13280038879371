.search-box {
  display: grid;
  margin: auto;
  margin-top: 2%;
  width: 70%;
  grid-template-columns: 1.5fr 1fr;
  align-items: center;
}
.inbox-search {
  width: 100%;
  padding: 1% 2%;
  height: 40px;
  text-decoration: none;
  outline: none;
}
.search-btn {
  background-color: #01aced !important;
  margin-left: 1% !important;
  /* border-radius: 15px !important; */
  width: 20% !important;
  color: white !important;
  margin-top: 0.5% !important;
  margin-bottom: 0.7%!important;
  margin-right: -2% !important;
}

.service-image {
  margin-top: 3%;
  /* margin-left: 55%; */
  /* height: 5%; */
  width: 50%;
}
.dealer-hero-page {
  display: flex;
  justify-content: space-around;
}
.dealer-hero-heading {
  margin-top: 12%;
  font-size: 2.5rem;
  color: rgba(0, 0, 0, 0.726);
  letter-spacing: 0.2px;
}

.sorting-div {
  text-align: center;
}

.sorting-div h5 {
  display: inline;
}

.sort-select {
  margin: auto;
  border: 2px solid #01aced;
  border-radius: 5px;
  font-weight: 500;
}

.sort-select:focus {
  border-radius: 5px;
}

/* **************************************New Card CSS**************************************** */

.quotes-card-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  margin: auto;
  margin-top: 4%;
  padding: 2%;
}
.quotes-card-main-container a {
  text-decoration: none;
  color: inherit;
}
.icon-detail {
  width: 50%;
}

.quotes-card-container {
  border-radius: 20px 20px 0px 0px;
}

.quote-card-stack {
  /* animation: fadeInLeft 1s; */
  border-radius: 20px 20px 0px 0px;
}

/* .quote-card-stack-img img {
  width: 75%;
  display: block;
  padding: 1%;
  margin: 1% auto;
} */

.quote-card-stack-img .MuiBadge-anchorOriginTopLeftRectangle{

  transform : scale(1) translate(15%, -50%) !important

}

.quote-card-stack-text {
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: aliceblue;
  padding: 2%;
  padding-bottom: 8%;
  border-radius: 20px 20px 0px 0px;
}
.quote-card-stack-text h5 {
  margin-left: 5%;
}

.quote-card-stack-img p{
  width: 100%;
  background-color: crimson;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  margin: auto;
  padding: 3% 0%;
  margin: 5% 0%;
}

.quote-card-stack-text small {
  margin-left: 5%;
}
.quote-card-stack-down {
  border-radius: 20px 20px 0px 0px;
  margin-top: -8%;
  background-color: whitesmoke;
  padding: 5%;
  /* animation: fadeInLeft 1s; */
}
.quotes-card-container {
  margin: 5%;
  background-color: whitesmoke;
  box-shadow: 0 5px 12px rgb(0 0 0 / 0.2);
}

.quotes-card-container:hover {
  transition: all 0.5s;
  transform: translateY(-10px);
  /* background-image: linear-gradient(to top, rgb(238, 238, 238), rgb(255, 255, 255)); */
  background-image: whitesmoke;
}

.quote-id h5 {
  display: inline;
}

.information-container div h5 {
  text-align: center;
}

.information-container div p {
  text-align: center;
}

.buttons-div {
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;
}

.buttons-div button {
  /* margin: 2% auto; */
  padding: 3% 8%;
  width: 90%;
  margin-bottom: 5%;
  border: none;
  font-weight: 500;
  border-radius: 5px;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );

  color: white;
}

.buttons-div button a {
  text-decoration: none;
  color: inherit;
}

.buttons-div button:hover {
  background-color: #038cc2;
}
/* ********************************************************************************************** */

@media only screen and (max-width: 700px) {
  .dealer-hero-page {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .service-image {
    margin-top: 0%;
    width: 90%;
    margin-left: 5%;
  }
  .dealer-hero-heading {
    text-align: center;
    font-size: 1.9rem;
  }
  .inbox-search {
    width: 100%;
  }
  .search-box {
    margin: auto;
    width: 90%;
  }
  .search-btn {
    width: 25% !important;
    margin-left: 2% !important;
  }
  .quotes-card-main-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0%;
  }
}

@media (max-width: 768px) {
  .quotes-card-main-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0%;
  }
}
@media (max-width: 425px) {
  .quotes-card-main-container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0%;
  }
}

@media (max-width: 500px) {
  .search-box {
    grid-template-columns: 1fr;
  }
  .sorting-div {
    margin-top: 3%;
  }
}
