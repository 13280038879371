*{

    font-family: 'Heebo', sans-serif;
    letter-spacing: 0.5px;
}

h1{
    font-family: 'Poppins', sans-serif;
}

h2{
    font-family: 'Poppins', sans-serif;
}

h3{
    font-family: 'Poppins', sans-serif;
}

h4{
    font-family: 'Poppins', sans-serif;
}

h5{
    font-family: 'Poppins', sans-serif;
}

h6{
    font-family: 'Poppins', sans-serif;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number]{
    -moz-appearance: textfield;
}