/* **********************************strike through css********************************** */
.strikethrough {
  display: inline-block;
  position: relative;
  cursor: default;
  color: #3498db;
  transition: all 1s cubic-bezier(0.55, 0, 0.1, 1);
}

.strikethrough:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 4px;
  box-shadow: 0 1px rgba(255, 255, 255, 0.6);
  margin-top: -0.7em;
  background: #3498db;
  /* transform-origin: center left;
  animation: strikeThrough 2s 0.5s cubic-bezier(0.55, 0, 0.1, 1) 1;
  transition: transform 3s cubic-bezier(0.55, 0, 0.1, 1); */
}

/* **************************************strikethrough css ends************************************** */

.hero-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

s {
  color: #3498db;
}
.btnlogin {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
  border-radius: 10px; /*50px*/
  background: #fff;
  width: 130px;
  color: #000;
  text-decoration: none;
  text-align: center;
}

#header h1 {
  font-size: 68px;
  font-weight: 600;
  color: black;
  margin: 2% 0%;
}

#header .sub-title {
  color: #000;
  font-family: cursive;
  font-weight: 400;
  font-size: 35px;
  font-style: italic;
  margin: 1% 0%;
}

#header h2 {
  color: #3498db;
  font-size: 24px;
  margin: 1% 0%;
}
.btn-get-started {
  width: 15%;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  display: block;
  padding: 10px 30px;
  margin: 20px auto;
  border-radius: 10px; /*50px*/
  transition: 0.2s;
  background: #3498db;
  color: #fff;
  text-decoration: none;
  text-align: center;
}
.btn-get-started:hover {
  color: rgb(230, 228, 228);
  background: #067bc9;
}
.header-img {
  text-align: right;
}
#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 768px) {
  .btn-get-started {
    width: 30%;
    font-size: 14px;
    padding: 2% 2%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes strikeThrough {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
