* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Signupform form label {
  padding: 0% 1% !important;
  background-color: white !important;
}

.dialog-form label {
  padding: 0% 1% !important;
  background-color: white !important;
}

.Signupform form fieldset {
  top: 0px !important;
}

.Signupform {
  margin-top: 5%;
  margin-left: 20%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 60%;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 2%;
}

.signup-profile-save-btn {
  background-color: #01aced !important;
  margin-left: 1% !important;
  border-radius: 15px !important;
  width: 20%;
  color: white !important;
}

.signup-dialog MuiDialogActions-root {
  justify-content: center;
}
.Signupform h2 {
  font-size: 20px;
}

.Signupform button {
  background: transparent;
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
}

.buttons {
  display: flex;
  justify-content: center;
}

.Signupform .b1 {
  color: aliceblue;
  background: #03a9fa;
}

.Signupform .b1:hover {
  background-color: rgba(85, 138, 224);
  color: aliceblue;
}

.signup-next-btn {
  background-color: #01aced !important;
  color: white !important;
  width: 20%;
  font-size: large;
}

@media only screen and (max-width: 700px) {
  .Signupform {
    margin-left: 5%;
    width: 90%;
  }
  .signup-next-btn {
    width: 40%;
  }
}

.select__menu {
  position: absolute !important;
  display: block;
  z-index: 3 !important;
  background-color: white !important;
}
