.hero-section {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  padding: 1%;
  margin: 2% 0%;
  justify-items: center;
}

.hero-text-section {
  width: 90%;
  margin-left: 10%;
  margin-top: 5%;
}

.hero-img-section {
  width: 100%;
  margin: auto;
}

.hero-img {
  width: 80%;
  display: block;
  margin: 1% auto;
}

.hero-btn {
  text-align: center;
}

/* section 2 */

.section2-card-even {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2%;
  border-radius: 2% 2% 2% 2%;
  box-shadow: 0 0 10px #8888;
  margin: 5% auto;
  align-items: center;
}

.section2-card-odd {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2%;
  border-radius: 2% 2% 2% 2%;
  box-shadow: 0 0 10px #8888;
  margin: 5% auto;
  align-items: center;
}

.section2-img {
  display: block;
  margin: 1% auto;
  width: 70%;
}

.section-2-img {
  width: 55%;
}

.section2-card-text {
  margin: 2% 1%;
}

.section2-card-text h2 {
  color: #3498db;
  margin: 2% 1%;
}

.section2-card-text p {
  margin: 2% 1%;
  color: rgb(97, 97, 97);
}

/* section 3 */

.section3-card {
  align-items: center;
  padding: 1%;
  margin: 10% 0%;
  background: linear-gradient(rgb(161, 236, 241), rgb(95, 133, 238));
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.section3-card-img {
  margin: 1% 2%;
  width: 100%;
}
.section3-card-text {
  width: 100%;
}

.section3-card-text h2 {
  color: white;
  font-size: 40px;
  margin: 2% 1%;
}

.section3-card-text p {
  color: rgb(97, 97, 97);
  font-size: 20px;
  margin: 2% 1%;
}

.section3-img {
  width: 100%;
  margin: 1%;
}

/* section 4 */

.section4-card {
  margin: 5% 1%;
  text-align: center;
}

.section4-card h1 {
  color: #3498db;
  margin: 2% 1%;
}

.section4-card p {
  color: rgb(97, 97, 97);
  margin: 2% 1%;
  font-weight: 500;
  font-size: 18px;
}

/* section 5 */

.section5-card img {
  width: 75%;
  display: block;
  margin: auto;
}

@media (max-width: 800px) {
  .hero-section {
    grid-template-columns: 1fr;
    padding: 3% 1%;
  }
  #header {
    margin-top: 10px;
  }
  #header h1 {
    font-size: 38px;
  }
  #header h5 .sub-title {
    font-size: 22px;
  }
  #header h2 {
    font-size: 17px;
    margin-bottom: 30px;
  }
  #header .header-img img {
    width: 70%;
  }
  .hero-img {
    width: 100%;
  }
  .section2-card-even {
    flex-direction: column;
    width: 90%;
  }

  .section2-card-odd {
    flex-direction: column-reverse;
    width: 90%;
  }

  .section2-img {
    width: 55%;
  }

  .hero-btn {
    text-align: center;
  }

  .section3-card-text h2 {
    font-size: 20px;
  }

  .section3-card-text p {
    font-size: 14px;
  }
}
