.header-nav {
  /* background: #3498DB; */
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 99;
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.logo {
  font-weight: 600;
  font-size: 25px;
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif;
}

.logo-img{
  width: 100%;
}

.logo font {
  color: #fff;
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  animation: fadeInRight 0.5s linear;
}

.menu-list li a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  padding: 5px 10px;
  margin-left: 20px;
}

.menu-list li a:hover,
.menu-list li a.active {
  background-color: #fff;
  border-radius: 20px;
  color: #000;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 800px) {
  .logo {
    font-size: 20px;
  }
  .menu-list li a {
    font-size: 12px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  .menu-icon {
    display: block;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    right: 0;
    top: 64px;
    transition: all 0.5s ease-in-out;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    width: 100px;
    display: block;
    margin: auto;
  }

  .close {
    animation: fadeOutRight 0.5s linear;
    display: none;
  }
}

