
.quotes-list {
  margin-left: 10%;
  width: 80%;
}
.quotes-heading {
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 5%;
}

/* ******************************************New Card CSS********************************************* */

.customer-quotes-card-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* animation: fadeInLeft 1s; */
  width: 90%;
  margin: auto;
  margin-top: 4%;
  padding: 2%;
}
.customer-icon-detail {
  width: 50%;
}
.customer-head-title {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 2% 0%;
}

.customer-head-image img {
  width: 100%;
}

.quotes-heading {
  margin-left: 10%;
  margin-top: 3%;
  margin-bottom: 5%;
}
.customer-quote-card-stack-text {
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: aliceblue;
  padding: 2%;
  padding-bottom: 5%;
  border-radius: 20px 20px 0px 0px;
}
.customer-quote-card-stack-text h5 {
  margin-left: 5%;
}
.customer-quote-card-stack-text small {
  margin-left: 5%;
}
.customer-quote-card-stack-down {
  border-radius: 20px 20px 0px 0px;
  margin-top: -5%;
  background-color: whitesmoke;
  padding: 5%;
}
.customer-quotes-card-container {
  margin: 5%;
  background-color: whitesmoke;
  box-shadow: 0 5px 12px rgb(0 0 0 / 0.2);
}

.customer-quotes-card-container:hover {
  transition: all 0.5s;
  transform: translateY(-10px);
  /* background-image: linear-gradient(to top, rgb(238, 238, 238), rgb(255, 255, 255)); */
  background-image: whitesmoke;
}

.customer-quotes-card-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* animation: fadeInLeft 1s; */
  width: 90%;
  margin: auto;
  margin-top: 4%;
  padding: 2%;
}
.customer-icon-detail {
  width: 50%;
}

.customer-quotes-card-container {
  border-radius: 20px 20px 0px 0px;
}

.customer-quote-card-stack {
  border-radius: 20px 20px 0px 0px;
}

.customer-quote-card-stack-text {
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: aliceblue;
  padding: 2%;
  padding-bottom: 8%;
  border-radius: 20px 20px 0px 0px;
}

.customer-quote-card-stack-icon-wrapper{

  display: flex;
  align-items: center;
}

.customer-quote-card-stack-text h4 {
  text-align: center;
  cursor: pointer;
  display: block;
  margin: auto;
  font-size: 18px;
}
.customer-quote-card-stack-text h5 {
  margin-left: 5%;
}
.customer-quote-card-stack-text small {
  margin-left: 5%;
}

.customer-quote-card-stack-down {
  border-radius: 20px 20px 0px 0px;
  margin-top: -8%;
  background-color: whitesmoke;
  padding: 5%;
}
.customer-quotes-card-container {
  margin: 5%;
  background-color: whitesmoke;
  box-shadow: 0 5px 12px rgb(0 0 0 / 0.2);
}

.customer-quotes-card-container:hover {
  transition: all 0.5s;
  transform: translateY(-10px);
  /* background-image: linear-gradient(to top, rgb(238, 238, 238), rgb(255, 255, 255)); */
  background-image: whitesmoke;
}

.buttons-div-customer {
  display: block;
  width: 100%;
  margin: auto;
  text-align: center;
}

.buttons-div-customer button {
  display: grid;
  grid-template-columns: 1fr 0.3fr;
  padding: 2% 0% 3% 8%;
  width: 80%;
  margin: 2% auto;
  margin-bottom: 5%;
  border: none;
  font-weight: 600;
  border-radius: 5px;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );

  color: white;
  text-decoration: none;
}
.buttons-div-customer button p {
  margin: 0%;
  padding-top: 2%;
}
.buttons-div-customer a {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

.buttons-div-customer button:hover {
  background-color: #038cc2;
}

/* ****************************************************************************************************** */

/* ************************************No Data Illustration CSS***************************************** */

.no-data-illustration {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 1% 0%;
}

.no-data-img-div {
  margin: 1%;
}

.no-data-img-div img {
  width: 60%;
  display: block;
  margin: auto;
}

.no-data-text-btn-div {
  margin: 1%;
  text-align: center;
}

.no-data-text-btn-div button {
  margin: 3% 0%;
  font-weight: 600;
}
/* **************************************************************************************************** */

@media only screen and (max-width: 769px) {
  .customer-quotes-card-main-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 450px) {
  .no-data-illustration {
    grid-template-columns: 1fr;
  }
  .no-data-text-btn-div {
    margin: 2%;
  }
  .customer-quotes-card-main-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 550px) {
  .customer-head-title {
    grid-template-columns: 1fr;
  }
}
