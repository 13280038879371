.Footer-container {
  padding-top: 40px;
  background: rgb(1, 68, 138);
  background: linear-gradient(
    90deg,
    rgba(4, 87, 175, 0.858) 0%,
    rgba(27, 116, 199, 0.846) 20%,
    rgba(52, 152, 219, 1) 100%
  );
  color: #fff;
  margin-top: 5%;
  padding: 5%;
  padding-bottom: 1%;
  position: static;
  /* width: 100vw; */
  left: 0;
  bottom: 0;
}

.Footer-Section1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}

.subSection1-heading {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 22px;
}

.subSection2-heading {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 22px;
}

.subSection-listContainer {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 20px;
  margin-bottom: 0;
}

.subSection-listItems {
  color: inherit;
  text-decoration: none;
  opacity: 1;
}

.subSection-listItems:hover {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.Section1-subSection3-heading {
  margin-bottom: 0;
  font-size: 20px;
}

.social-media-icon {
  color: #fff;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 12px;
}

.social-media-icon:hover {
  opacity: 0.7;
}

.Footer-Section2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 2% 0%;
  justify-items: center;
}
.Footer-Section2-subSection2 {
  width: 50vw;
  display: flex;
  justify-content: center;
}
.subSection-text {
  margin-right: 0.5rem;
}

@media (max-width: 750px) {

  .Footer-Section1 {
    grid-template-columns: 1fr 1fr;
  }
  .Footer-Section1-subSection2 {
    margin-left: 0rem;
  }

  .Footer-Section1-subSection3 {
    box-sizing: border-box;
    margin-top: 19px;
    margin-left: 0rem;
    padding-left: 12px;
    grid-column: 1 / span 2;
    display: flex;
  }

  #socialMedia-icon {
    padding-left: 29px;
  }
  .social-media-icon {
    color: #fff;
    margin-top: 8px;
    margin-left: 12px;
  }

  .Footer-Section2 {
    grid-template-columns: 1fr;
    align-items: center;
    margin-top: 8%;
  }

  .Footer-Section2-subSection1 {
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  .subSection-text {
    font-size: 1.2rem;
    display: grid;
    padding-left: 0.8rem;
  }

  .Footer-Section2-subSection1:nth-of-type(1) {
    order: 2;
  }
  .Footer-Section2-subSection1:nth-of-type(2) {
    order: 1;
  }

  .Footer-Section2-subSection2 {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    justify-content: center;
    width: 100%;
  }
}

