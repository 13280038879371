.all-quotes-drop-down{

  width: 50%;
  margin: 2% auto;
  border: 1px solid #3498db;
  border-radius: 10px;
}

.accordian-dropdown-options{

  height: 300px;
  overflow-y: auto;
  background-color: white !important;
}

.accordian-dropdown-options::-webkit-scrollbar {
  width: 10px;
}

.accordian-dropdown-options::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.quotes-drop-down{

  background-color: #3498db42;

}

.all-quotes-drop-down div{

  border-radius: 10px !important;
}

.all-quotes-drop-down div div{

  border-radius: 10px !important;

}

.all-quotes-drop-down button{

  border-radius: 10px !important;
}

.dropdown-layout{

  display: flex;
  justify-content: space-between;
  padding: 1% 1%;
  border-bottom: 2px solid #3498db;
  cursor: pointer;
  border-radius: 0px !important;
}

.dealer-quotes-card {
  padding: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 80%;
  margin: 5% auto;
  margin-top: 3%;
}
.dealer-quotes-card h1 {
  font-size: x-large;
  margin-left: 5%;
}
.dealer-quote-top {
  padding: 2% 5%;
}
.dealer-quote-top p {
  font-size: x-large;
  font-weight: 500;
}
.dealer-quote-top2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.customer-detail-alert {
  background-color: rgba(155, 153, 153, 0.418);
  padding: 2%;
  border-radius: 5px 5px 5px 5px;
  width: 35%;
  height: 100px;
}
.quote-customer-detail {

  text-align: left;
  width: 90%;
  margin: 2% auto;
}
.quote-customer-detail p {
  margin-bottom: 1%;
}
.quote-customer-detail p span{

    font-weight: 500;
}
.quote-actual-details {
  margin: auto;
  width: 70%;
  margin-top: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* background-color: rgba(155, 153, 153, 0.418); */
  padding: 2%;
  border-radius: 5px 5px 5px 5px;
  overflow: auto;
  height: 300px;
}
.quotes-button-div {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5%;
}
.edit-button {
  background-color: #01aced !important;
  color: white !important;
  width: 10%;
}
.cancel-button {
  background-color: #01aced !important;
  color: white !important;
  width: 10%;
}
.save-button {
  background-color: #01aced !important;
  color: white !important;
  width: 10%;
}

.confirm-button {
  background-color: #1ed82e !important;
  color: white !important;
  width: 25% !important;
  padding: 2% !important;
}
.discard-button {
  background-color: #ec422b !important;
  color: white !important;
  width: 25% !important;
  padding: 2% !important;
}

@media (max-width: 768px) {

  .all-quotes-drop-down{

    width: 75%;
  }

  .dealer-quotes-card {
    width: 95%;
  }
  .dealer-quote-top2 {
    flex-direction: column;
  }
  .customer-detail-alert {
    width: 80%;
    height: auto;
    margin: 4% auto;
    padding: 4% 4%;
    text-align: justify;
  }

  .quote-customer-detail {
    padding: 1%;
    text-align: left;
    width: 90%;
    margin: 4% auto;
  }
}

@media (max-width: 425px) {

  .all-quotes-drop-down{

    width: 80%;
  }
}
