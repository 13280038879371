* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-heading h1 {
  font-size: 5rem;
  color: #fff;
  font-weight: 300;
  background: rgba(0, 0, 0, 0.5);
  padding: 0px 20px;
  border-radius: 2px;
}
